.header {
  align-items: center;
  background-color: #3a89f7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 95px;
  padding: 0 0 0 80px;
  width: 100%;
  color: #fff;

  a {
    text-decoration: underline;
  }

  .status-bar {
    display: flex;
    flex-direction: row;
    height: 100%;

    .status-container {
      align-items: center;
      background-color: #075287;
      display: flex;
      flex-direction: row;
      padding: 0 25px;

      > span {
        margin-left: 25px;
      }
    }
  }
}
