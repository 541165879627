.logo {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  gap: 1em;
  letter-spacing: 2px;

  * {
    line-height: 1;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    .row-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 25px;
      .thin {
        font-weight: 300;
      }
      .icon {
        height: 1em;
        width: auto;
        margin-right: 0.3em;
      }
    }
    .row-bottom {
      font-size: 20px;
    }
  }
}
